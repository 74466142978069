<template>
  <div class="page-user-registerview">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field
            required
            readonly
            v-model="viewData.Name"
            name="Name"
            label="幼儿园名称"
            type="text"
            placeholder="请输入幼儿园名称"
            :rules="[{ required: true, message: '请输入幼儿园名称' }]"
          />
          <van-field
            required
            v-model="viewData.Desc"
            name="Desc"
            rows="5"
            label="幼儿园简介"
            type="textarea"
            placeholder="请输入幼儿园简介"
            :rules="[{ required: true, message: '请输入幼儿园简介' }]"
          />
          <van-field
            required
            v-model="viewData.CellPhone"
            name="CellPhone"
            type="number"
            label="联系电话"
            placeholder="请输入联系电话"
            :rules="[{ required: true, message: '请输入联系电话' }]"
          />
          <van-field
            required
            v-model="viewData.Address"
            name="Address"
            rows="2"
            label="地址"
            type="textarea"
            placeholder="请输入地址"
            :rules="[{ required: true, message: '请输入地址' }]"
          />
          <van-field required name="SchoolPhotoList" label="校园风采">
            <template #input>
              <van-uploader
                v-model="viewData.SchoolPhotoList"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
          <van-field required name="SchoolHonorList" label="校园荣誉">
            <template #input>
              <van-uploader
                v-model="viewData.SchoolHonorList"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead2"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <van-button type="primary" block color="#ffe500" native-type="submit"
          >保存</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import { Button, Form, Field, Uploader, Toast } from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Toast.name]: Toast,
  },
  data() {
    return {
      minDate: new Date(),
      id: null,
      viewData: {
        SchoolPhotoList: [],
        SchoolHonorList: [],
      },
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$axios
        .get("/api/School/Get", {
          id: this.id,
        })
        .then((res) => {
          this.viewData = {
            ...res.data,
            SchoolPhotoList: res.data.schoolPhotoList,
            SchoolHonorList: res.data.schoolHonorList,
          };
        });
    },
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                this.viewData.SchoolPhotoList.push({
                  name: file.name,
                  osskey: res.data.osskey,
                  uploadUrl: res.data.uploadUrl,
                  url: res.data.url,
                });
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            this.viewData.SchoolPhotoList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url,
            });
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    beforeRead2(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                this.viewData.SchoolHonorList.push({
                  name: file.name,
                  osskey: res.data.osskey,
                  uploadUrl: res.data.uploadUrl,
                  url: res.data.url,
                });
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            this.viewData.SchoolHonorList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url,
            });
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    save() {
      let that = this;
      const params = {
        ID: this.id,
        Address: this.viewData.Address,
        CellPhone: this.viewData.CellPhone,
        Desc: this.viewData.Desc,
        SchoolPhotoList: this.viewData.SchoolPhotoList,
        SchoolHonorList: this.viewData.SchoolHonorList,
      };
      this.$axios.post("/api/School/EditForApp", params).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg || "操作成功");
          setTimeout(() => {
            that.$router.replace({
              path: "SchoolInfo",
            });
          }, 1000);
        } else {
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
